import { Commit } from 'vuex';
import { toFirstUpperCase } from '@/utils/StringUtils';

function setterName(field: string | number | symbol) {
    const fieldName: string = field.toString();
    return 'set' + toFirstUpperCase(fieldName);
}

export function setterForFields<T>(vuexMutation: string, fields: Array<keyof T>) {
    const setField = (commit: Commit, field: any): void => commit(vuexMutation, field);
    const setterMethods: any = {};
    fields.forEach(field => {
        setterMethods[setterName(field)] = (commit: Commit, value: any) => setField(commit, { [field]: value })
    });
    return setterMethods;
}
