
























import { Component, Vue, Watch } from 'vue-property-decorator';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import CoLayout from '@/layouts/CoLayout.vue';
import { SrActions } from '@/store/sr';
import { SopActions } from '@/features/sops';
import SopDetailHeader from '@/components/sop-detail/SopDetailHeader.vue';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { RoleActions } from '@/store/roles';
import { UserActions } from '@/store/users';
import SopPdf from '@/components/sop-detail/SopPdf.vue';
import { TemplateActions } from '@/store/templates';
import GapListWidget from '@/features/gap-list/gap-list-widget/GapListWidget.vue';
import StackedContent from '@/components/common/StackedContent.vue';
import SopContent from '@/components/sop-detail/SopContent.vue';
import SopBlockDetail from '@/features/sop-block/sop-block-detail/SopBlockDetail.vue';
import SopNavigationTree from '@/features/sops/sop-navigation-tree/SopNavigationTree.vue';
import { RegulationActions } from '@/features/regulations/regulation-store';
import { SopArtifact } from '@/model';
import { GapListWidgetActions } from '@/features/gap-list/gap-list-widget/gap-list-widget-store';

@Component({
  components: { SopNavigationTree, StackedContent, SopPdf, GapListWidget, SopDetailHeader, CoLayout, SopContent, SopBlockDetail },
  computed: {
    ...mapState<RootState>({
      sopArtifact: (state: RootState) => state.sopDetail.sopArtifact,
      sop: (state: RootState) => state.sopDetail.sop,
      contentStack: (state: RootState) => state.sopDetail.contentStack,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE
    })
  }
})
export default class SopDetailView extends Vue {

  sopsLoaded = false;

  sopArtifact: SopArtifact;

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.loadSops().then(this.loadSopDetail);
  }

  created() {
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
    this.$store.dispatch(RoleActions.ROLES_LOAD);
    this.$store.dispatch(UserActions.USERS_LOAD);

    this.$store.dispatch(TemplateActions.TEMPLATES_SEARCH);
    this.$store.dispatch(RegulationActions.REGULATIONS_LOAD);
    this.$store.dispatch(SrActions.SR_LOAD);
    this.$store.dispatch(SrActions.SR_TEMPLATE_CONTENTS_LOAD);
    this.$store.dispatch(SrActions.SR_PROCESS_CONTENTS_LOAD);
  }

  get widgetConfig(): any[] {
    let requirementTypeWidgets: any[] = [];
    if (this.sopArtifact?.requirementType === 'PRODUCT') {
      requirementTypeWidgets = [
        { displayName: 'Requirement List', componentName: 'ProductRequirementWidget', icon: 'mdi-package-variant-closed' },
      ];
    } else {
      requirementTypeWidgets = [
        { displayName: 'Gap List', componentName: 'GapListWidget', icon: '$coGapList' },
        { displayName: 'Templates', componentName: 'SopTemplatesWidget', icon: '$coTemplate' },
        { displayName: 'Template Contents', componentName: 'SopTemplateContentWidget', icon: '$coTemplateContent' },
        { displayName: 'Linked S&R Processes', componentName: 'SrProcessAndRequirements', icon: '$coAppliedSr' },
      ];
    }
    return [
      ...requirementTypeWidgets,
      { displayName: 'Release Workflow', componentName: 'SopChangeControl', icon: 'mdi-source-branch' },
      { displayName: 'Document History', componentName: 'SopDocumentHistory', icon: 'mdi-history' },
      { displayName: 'Comments', componentName: 'CommentsWidget', icon: 'mdi-comment-outline' },
    ];
  }

  private loadSops(): Promise<void> {
    if (this.sopsLoaded) {
      return Promise.resolve();
    }
    this.sopsLoaded = true;
    return this.$store.dispatch(SopActions.SOPS_LOAD);
  }

  private loadSopDetail() {
    if (this.$route.params.artifactId) {
      this.$store.dispatch(SopDetailActions.SOP_DETAIL_LOAD_CURRENT, this.$route.params.artifactId)
          .then(() => this.scrollToHash());
    } else if (this.$route.params.id) {
      this.$store.dispatch(SopDetailActions.SOP_DETAIL_LOAD, this.$route.params.id)
          .then(() => this.scrollToHash());
    }
  }

  private scrollToHash() {
    const hash = this.$route.hash;
    if (hash.startsWith('#requirement-id=')) {
      const paramValues = hash.substr('#requirement-id='.length).split('---');
      const regulationId = paramValues[0];
      const requirementId = paramValues[1];
      this.$store.dispatch(GapListWidgetActions.GAP_LIST_WIDGET_REGULATION_SELECT, regulationId)
          .then(() => this.$store.dispatch(GapListWidgetActions.GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID, requirementId));
    } else if (hash.startsWith('#')) {
      // set hash again, since the content will be loaded async
      this.$nextTick(() => window.location.replace(hash));
    }
  }

  beforeDestroy() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_RESET);
  }
}
