




















import Component, { mixins } from 'vue-class-component';
import SopBlockEntryLayout from '@/features/sop-block/sop-block-list/SopBlockEntryLayout.vue';
import EditorHtmlWrapper from '@/components/common/EditorHtmlWrapper.vue';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';

@Component({
  components: { EditorHtmlWrapper, SopBlockEntryLayout }
})
export default class SopBlockEntryTiptap extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

}
