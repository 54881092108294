
import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import Component from 'vue-class-component';
import { SopBlockDetailActions, SopBlockDetailHelper } from '@/features/sop-block/sop-block-detail/store';
import { RootState } from '@/store';
import { SopBlock } from '@/features/sop-block/model';

@Component({
  computed: {
    ...mapState<RootState>({
      block: (state: RootState) => state.sopBlockDetail.selectedSopBlock,
    }),
  },
  methods: {
    ...mapMutations({
      ...SopBlockDetailHelper.setterForSopBlockFields('name', 'templateContentIds', 'roleId', 'description'),
    }),
    ...mapActions({
      save: SopBlockDetailActions.SOP_BLOCK_DETAIL_SAVE_AND_CLOSE,
      cancel: SopBlockDetailActions.SOP_BLOCK_DETAIL_CANCEL,
    })
  }
})
export default class SopBlockDetailMixin extends Vue {

  block: SopBlock;

}
