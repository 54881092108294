

























































































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import { SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';
import { SopBlockListActions } from '@/features/sop-block/sop-block-list/store';
import AddSopBlockButtons from '@/features/sop-block/sop-block-list/AddSopBlockButtons.vue';
import { CommentRef, CommentsWidgetActions, CommentsWidgetGetters } from '@/features/comments/store';
import { RootState } from '@/store';
import { UiLayoutActions } from '@/store/ui-layout';
import { GapListWidgetGetters } from '@/features/gap-list/gap-list-widget/gap-list-widget-store';
import { SopBlockVersionState } from '@/features/sop-block/model';
import { toHumanReadable } from '@/utils/StringUtils';
import { SopArtifact } from '@/model';
import { ProductRequirementsGetters } from '@/features/product-requirements/product-requirements-store';

@Component({
  components: { AddSopBlockButtons },
  computed: {
    ...mapState<RootState>({
      selectedSopBlockVersionId: (state: RootState) => state.sopBlockList.selectedSopBlockVersionId,
      commentRef: (state: RootState) => state.comments.commentRef,
      sopArtifact: (state: RootState) => state.sopDetail.sopArtifact,
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE,
      hasBlockAnyComments: CommentsWidgetGetters.REF_ID_HAS_ANY_COMMENTS,
      hasBlockAnyLinkedRequirements: GapListWidgetGetters.GAP_LIST_WIDGET_HAS_SOP_BLOCK_ANY_LINKED_REQUIREMENTS,
      isBlockMarkedByRequirement: GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REQUIREMENT,
      isBlockMarkedByRegulation: GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REGULATION,
      isBlockMarkedByImpactTo: GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_TO,
      isBlockMarkedByImpactFrom: GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_FROM,
      hasBlockAnyLinkedQmsRequirements: ProductRequirementsGetters.HAS_QMS_REQUIREMENTS_BY_SOP_BLOCK_ARTIFACT_ID,
    }),
  },
  methods: {
    ...mapActions({
      createNewBlockArtifact: SopBlockListActions.SOP_BLOCK_LIST_CREATE_NEW_ARTIFACT,
      createNewBlockVersion: SopBlockListActions.SOP_BLOCK_LIST_CREATE_NEW_VERSION,
      deleteBlock: SopDetailActions.SOP_DETAIL_BLOCK_DELETE,
      undoDeletedBlock: SopDetailActions.SOP_DETAIL_BLOCK_DELETE_UNDO,
    })
  }
})
export default class SopBlockEntryLayout extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

  commentRef: CommentRef;
  selectedSopBlockVersionId: string;
  sopArtifact: SopArtifact;
  hasBlockAnyComments: (s: string) => boolean;
  isBlockMarkedByRequirement: (blockArtifactId: string) => boolean;
  isBlockMarkedByRegulation: (blockArtifactId: string) => boolean;
  isBlockMarkedByImpactTo: (blockArtifactId: string) => boolean;
  isBlockMarkedByImpactFrom: (blockArtifactId: string) => boolean;

  fabOpen = false;
  deleteDialog = false;

  get isQmsRequirement() {
    return this.sopArtifact.requirementType === 'QMS';
  }

  closeDeleteDialog() {
    this.deleteDialog = false;
  }

  editBlock() {
    const blockSelectionEvent: SopBlockSelectedEvent = {
      sopArtifactId: this.sopArtifact.artifactId,
      artifactId: this.block.artifactId,
      versionId: this.block.versionId,
      forEditing: true,
      name: this.block.name,
      description: this.block.description,
    }
    this.$store.dispatch(SopBlockListEvents.SOP_BLOCK_SELECTED, blockSelectionEvent);
  }

  openGapListWidget() {
    return this.fireSopBlockSelectedEventAndShowWidget(this.sopArtifact.requirementType === 'QMS' ? 'GapListWidget' : 'ProductRequirementWidget');
  }

  openCommentWidget() {
    this.fireSopBlockSelectedEventAndShowWidget('CommentsWidget');

    const commentRef: CommentRef = {
      refId: this.block.artifactId,
      refType: 'SOP_BLOCK_ARTIFACT',
      parentRefId: this.block.sopArtifactId,
      parentRefType: 'SOP_ARTIFACT',
    };
    this.$store.dispatch(CommentsWidgetActions.COMMENTS_LOAD_AND_ACTIVATE_WIDGET, commentRef);
  }

  private fireSopBlockSelectedEventAndShowWidget(widgetName: string, forEditing = false) {
    const blockSelectionEvent: SopBlockSelectedEvent = {
      sopArtifactId: this.sopArtifact.artifactId,
      artifactId: this.block.artifactId,
      versionId: this.block.versionId,
      name: this.block.name,
      description: this.block.description,
      forEditing,
    }
    return this.$store.dispatch(SopBlockListActions.SOP_BLOCK_LIST_SELECT_BLOCK, blockSelectionEvent)
        .then(() => this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, widgetName));
  }

  humanReadableVersionState(vs: SopBlockVersionState): string {
    switch (vs) {
      case 'CREATED':
        return 'new';
      case 'UPDATED':
        return 'changed';
      default:
        return toHumanReadable(vs);
    }
  }

  get isBlockMarked() {
    return this.isBlockMarkedByRequirement(this.block.artifactId) || this.isBlockMarkedByRegulation(this.block.artifactId);
  }

  get isBlockMarkedTo() {
    return this.isBlockMarkedByImpactTo(this.block.artifactId);
  }

  get isBlockMarkedFrom() {
    return this.isBlockMarkedByImpactFrom(this.block.artifactId);
  }

  get isBlockSelected() {
    return this.selectedSopBlockVersionId == this.block.versionId
  }

}
