




























































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { createNewTemplateContent, TemplateContent } from '@/features/template-content/template-content-model';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { SrGetters } from '@/store/sr';
import { createComparator } from '@/utils/util';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  computed: {
    ...mapState<RootState>({
      srTemplateContents: (state: RootState) => [{ id: '', name: '# Not Linked' }, ...Object.values(state.sr.templateContents)
          .sort(createComparator('name'))],
    }),
    ...mapGetters({
      srTemplateContentById: SrGetters.SR_TEMPLATE_CONTENT_BY_ID,
      templateContents: TemplateContentGetters.FILTERED_TEMPLATE_CONTENTS,
    })
  },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID
    })
  },
})
export default class TemplateContentsOverview extends mixins<AuthMixin>(AuthMixin) {

  rules = [
    (value: string) => (!!value && value.length >= 1) || 'Minimum 1 character',
  ];
  valid = false;
  editDialog = false;
  deleteDialog = false;
  templateContent: TemplateContent = createNewTemplateContent();

  get headers() {
    const baseHeaders = [
      { text: 'Name', value: 'name', width: '25%', sortable: true },
      { text: 'Description', value: 'description', width: '35%' },
      { text: 'Example', value: 'exampleText', width: '35%' },
    ];
    if (this.isQmsAdmin) {
      baseHeaders.push({ text: '', value: 'actions', width: '5%', sortable: false });
    }
    return baseHeaders;
  }

  confirmSave() {
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_CREATE, this.templateContent)
        .then(() => this.cancel());
  }

  confirmDelete() {
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_DELETE, this.templateContent.id)
        .then(() => this.cancel());
  }

  cancel() {
    this.templateContent = createNewTemplateContent();
    this.editDialog = false;
    this.deleteDialog = false;
  }

  editItem(templateContent: TemplateContent) {
    const templateContentWithoutAddedFields = {
      ...templateContent,
      creatingTemplates: undefined,
      reportingTemplates: undefined,
      processes: undefined
    };
    this.templateContent = { ...templateContentWithoutAddedFields };
    this.editDialog = true;
  }

  deleteItem(templateContent: TemplateContent) {
    this.templateContent = { ...templateContent };
    this.deleteDialog = true;
  }

  createNew() {
    this.editDialog = true;
  }

}

