import { ArtifactChangedEvent } from '@/model/Events';

export enum SopBlockListEvents {
    SOP_BLOCK_SELECTED = 'SOP_BLOCK_SELECTED',
}

export type SopBlockSelectedEvent = { forEditing: boolean; sopArtifactId: string; name: string; description: string } & ArtifactChangedEvent

export const createEmptySopBlockSelectedEvent = (): SopBlockSelectedEvent => {
    return {
        sopArtifactId: '',
        forEditing: false,
        versionId: '',
        artifactId: '',
        name: '',
        description: '',
    }
}