

















import Component, { mixins } from 'vue-class-component';
import EditorWrapper from '@/components/common/EditorWrapper.vue';
import SopBlockDetailLayout from '@/features/sop-block/sop-block-detail/SopBlockDetailLayout.vue';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';

@Component({
  components: { SopBlockDetailLayout, EditorWrapper },
})
export default class SopBlockDetailTiptap extends mixins<SopBlockDetailMixin>(SopBlockDetailMixin) {

}
