





















import Vue from 'vue';
import Component from 'vue-class-component';
import ProductRegulations from '@/features/product-requirements/ProductRegulations.vue';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';

@Component({
  components: { ProductRegulations },
  computed: {
    ...mapState<RootState>({
      selectedSopBlockEvent: (state: RootState) => state.productRequirements.selectedSopBlockEvent,
    }),
  },
})
export default class ProductRequirementWidget extends Vue {
  selectedSopBlockEvent: SopBlockSelectedEvent;
}
