
















































import Vue from 'vue';
import Component from 'vue-class-component';

import { Template } from '@/model/Template';
import { FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY, TemplateContentActions, TemplateContentFilter, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { mapGetters } from 'vuex';
import { emptyTemplateContentFilter } from '@/features/template-content/template-content-model';

@Component({
  computed: {
    ...mapGetters({
      allCreatingTemplateIds: TemplateContentGetters.ALL_CREATING_TEMPLATES,
      allReportingTemplateIds: TemplateContentGetters.ALL_REPORTING_TEMPLATES,
    })
  }
})
export default class TemplateContentFilterSidebar extends Vue {

  filterUnassigned = {
    name: 'Unassigned',
    artifactId: FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY
  };

  filterText = '';
  filterCreatingTemplateArtifactIds: string[] = [];
  filterReportingTemplateArtifactIds: string[] = [];

  creatingTemplates: Template[] = [];
  reportingTemplates: Template[] = [];

  applyFilter() {
    const templateContentFilter: TemplateContentFilter = {
      filterText: this.filterText ? this.filterText : '',
      filterCreatingTemplateArtifactIds: this.filterCreatingTemplateArtifactIds,
      filterReportingTemplateArtifactIds: this.filterReportingTemplateArtifactIds,
    };
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_FILTER, templateContentFilter);
  }

  resetFilter() {
    const emptyFilter = emptyTemplateContentFilter();

    this.filterText = emptyFilter.filterText;
    this.filterCreatingTemplateArtifactIds = emptyFilter.filterCreatingTemplateArtifactIds;
    this.filterReportingTemplateArtifactIds = emptyFilter.filterReportingTemplateArtifactIds;

    this.applyFilter();
  }

}
